<template>
	<section>
		<section class="search-container">
			<a-form layout="inline" class="m-t-14">
				<a-form-item label="预警名称：">
					<a-input
						class="search-input"
						placeholder="请输入"
						v-model="formSearch.ruleName"
					/>
				</a-form-item>
				<a-form-item label="预警类型：">
					<a-select
						class="search-input"
						placeholder="全部"
						v-model="formSearch.alarmType"
					>
						<a-select-option value="">全部</a-select-option>
						<a-select-option
							v-for="item in typeList"
							:key="item.id"
							:value="item.id"
						>
							{{ item.typeName }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="预警等级：">
					<a-select
						class="search-input"
						placeholder="全部"
						v-model="formSearch.alarmGrade"
					>
						<a-select-option value="">全部</a-select-option>
						<a-select-option
							v-for="item in gradeList"
							:key="item.id"
							:value="item.gradeValue"
						>
							{{ item.gradeValue }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="启用状态：">
					<a-select
						class="search-input"
						placeholder="全部"
						v-model="formSearch.enableStatus"
					>
						<a-select-option value="">全部</a-select-option>
						<a-select-option value="1">启用</a-select-option>
						<a-select-option value="0">停用</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" class="m-r-8" @click="btnQuery"
						><a-icon type="search" />查询</a-button
					>
					<a-button class="m-r-8" @click="btnReset"
						><a-icon type="reload" />重置</a-button
					>
					<!-- <router-link :to="{ name: 'AlarmRuleDetail' }">
						<a-button class="m-r-8">规则库</a-button>
					</router-link> -->
				</a-form-item>
			</a-form>
		</section>
		<section class="list-table-container m-t-18">
			<a-table
				size="small"
				:columns="columns"
				:data-source="tableData"
				:pagination="{ total, hideOnSinglePage: true }"
				@change="pageNumChange"
			>
				<a-switch
					slot="enableStatus"
					slot-scope="text, record"
					:checked="text === 1"
					@click="enableAlarmRuleDetail(record)"
				/>
				<a slot="action" slot-scope="text, record">
					<a
						class="m-r-10"
						href="javascript: void(0)"
						@click="editAlarmRuleDetail(record, 'set')"
						>配置</a
					>
					<a
						href="javascript: void(0)"
						@click="editAlarmRuleDetail(record, 'copy')"
						>复制</a
					>
				</a>
			</a-table>
		</section>
		<a-drawer
			class="drawer_module"
			:title="'预警规则' + (editType === 'copy' ? '复制' : '配置')"
			placement="right"
			:width="360"
			:maskClosable="false"
			:visible="editVisible"
			@close="editVisible = false"
		>
			<a-form
				:form="form"
				:colon="false"
				:labelCol="{ span: 6 }"
				:wrapperCol="{ span: 18 }"
			>
				<a-form-item label="预警名称">
					<a-input
						v-decorator="[
							'ruleName',
							{ rules: [{ required: true, message: '推送名称为必填项' }] }
						]"
					/>
				</a-form-item>
				<a-form-item label="预警规则">
					当前气温不得高于
					<a-input-number v-decorator="['thresholdValue']" />
					&deg;C
				</a-form-item>
				<a-form-item label="预警类型">
					<a-select
						placeholder="请选择"
						:disabled="true"
						v-decorator="[
							'alarmType',
							{ rules: [{ required: true, message: '预警类型为必填项' }] }
						]"
					>
						<a-select-option value="">请选择</a-select-option>
						<a-select-option
							v-for="item in typeList"
							:key="item.id"
							:value="item.id"
						>
							{{ item.typeName }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="预警等级">
					<a-select
						placeholder="请选择"
						v-decorator="[
							'alarmGrade',
							{ rules: [{ required: true, message: '预警等级为必填项' }] }
						]"
					>
						<a-select-option value="">请选择</a-select-option>
						<a-select-option
							v-for="item in gradeList"
							:key="item.id"
							:value="item.id"
						>
							{{ item.gradeName }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="推送规则">
					<a-select
						placeholder="请选择"
						v-decorator="[
							'pushRuleId',
							{ rules: [{ required: true, message: '推送规则为必填项' }] }
						]"
					>
						<a-select-option value="">请选择</a-select-option>
						<a-select-option
							v-for="item in pushList"
							:key="item.id"
							:value="item.id"
						>
							{{ item.pushRuleName }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item :wrapper-col="{ span: 18, offset: 6 }">
					<a-button type="primary" @click="btnConfirm">保存</a-button>
					<a-button style="margin-left: 10px" @click="btnCancel">关闭</a-button>
				</a-form-item>
			</a-form>
		</a-drawer>
	</section>
</template>

<script>
	export default {
		name: 'AlarmRule',
		data() {
			return {
				form: this.$form.createForm(this),
				typeList: [],
				gradeList: [],
				pushList: [],
				formSearch: {
					ruleName: '',
					alarmType: '',
					alarmGrade: '',
					enableStatus: ''
				},
				searchData: {
					ruleName: '',
					alarmType: '',
					alarmGrade: '',
					enableStatus: '',
					current: 1,
					size: 10
				},
				columns: [
					{
						title: '序号',
						dataIndex: 'key',
						align: 'center'
					},
					{
						title: '预警名称',
						dataIndex: 'ruleName',
						align: 'center'
					},
					{
						title: '预警规则',
						dataIndex: 'ruleTemplate',
						align: 'center'
					},
					{
						title: '预警类型',
						dataIndex: 'typeName',
						align: 'center'
					},
					{
						title: '预警等级',
						dataIndex: 'gradeName',
						align: 'center'
					},
					{
						title: '推送规则',
						dataIndex: 'pushRuleName',
						align: 'center'
					},
					{
						title: '是否启用',
						dataIndex: 'enableStatus',
						align: 'center',
						scopedSlots: { customRender: 'enableStatus' }
					},
					{
						title: '操作',
						dataIndex: 'action',
						align: 'center',
						scopedSlots: { customRender: 'action' }
					}
				],
				tableData: [],
				total: 0,
				editVisible: false,
				editType: ''
			}
		},
		mounted() {
			this.getAlarmTypeAll()
			this.getAlarmGradeAll()
			this.getAlarmPushRuleAll()
			this.queryAlarmRuleDetail()
		},
		watch: {
			editVisible(nVal) {
				if (nVal && this.dataSelected) {
					this.$nextTick(() => {
						this.form.setFieldsValue({
							ruleName: this.dataSelected.ruleName || '',
							thresholdValue: this.dataSelected.thresholdValue || '',
							alarmType: this.dataSelected.alarmType || '',
							alarmGrade: this.dataSelected.alarmGrade || '',
							pushRuleId: this.dataSelected.pushRuleId || ''
						})
					})
				} else {
					this.$nextTick(() => {
						this.form.setFieldsValue({
							ruleName: '',
							thresholdValue: '',
							alarmType: '',
							alarmGrade: '',
							pushRuleId: ''
						})
					})
				}
			}
		},
		methods: {
			getAlarmTypeAll() {
				this.$api.getAlarmTypeAll().then((res) => {
					if (res.code === 200) {
						this.typeList = res.data
					}
				})
			},
			getAlarmGradeAll() {
				this.$api.getAlarmGradeAll().then((res) => {
					if (res.code === 200) {
						this.gradeList = res.data
					}
				})
			},
			getAlarmPushRuleAll() {
				this.$api.getAlarmPushRuleAll().then((res) => {
					if (res.code === 200) {
						this.pushList = res.data
					}
				})
			},
			queryAlarmRuleDetail() {
				this.$api.queryAlarmRuleDetail(this.searchData).then((res) => {
					if (res.code === 200) {
						this.tableData = (res.data && res.data.records)
							? res.data.records.map((item, index) => {
									item.key =
										(this.searchData.current - 1) * this.searchData.size +
										index +
										1
									item.ruleTemplate = item.ruleTemplate.replace(
										'#{max}',
										item.thresholdValue || ''
									)
									return item
							  })
							: []
						this.total = (res.data && res.data.total) || 0
					}
				})
			},
			pageNumChange(pagination) {
				this.searchData.current = pagination.current
				this.queryAlarmRuleDetail()
			},
			btnQuery() {
				this.searchData.ruleName = this.formSearch.ruleName
				this.searchData.alarmType = this.formSearch.alarmType
				this.searchData.alarmGrade = this.formSearch.alarmGrade
				this.searchData.enableStatus = this.formSearch.enableStatus
				this.searchData.current = 1
				this.queryAlarmRuleDetail()
			},
			btnReset() {
				this.searchData.ruleName = this.formSearch.ruleName = ''
				this.searchData.alarmType = this.formSearch.alarmType = ''
				this.searchData.alarmGrade = this.formSearch.alarmGrade = ''
				this.searchData.enableStatus = this.formSearch.enableStatus = ''
				this.searchData.current = 1
				this.queryAlarmRuleDetail()
			},
			enableAlarmRuleDetail(record) {
				this.dataSelected = record
				this.$api
					.enableAlarmRule({
						id: this.dataSelected.id,
						enableStatus: record.enableStatus === 1 ? 0 : 1
					})
					.then((res) => {
						if (res.code === 200) {
							this.queryAlarmRuleDetail()
						}
					})
					.catch((err) => {
						console.log(err)
					})
			},
			editAlarmRuleDetail(record, type) {
				this.dataSelected = record
				this.editVisible = true
				this.editType = type
			},
			btnConfirm() {
				this.form.validateFields((errors, values) => {
					if (!errors) {
						if (this.editType === 'set') {
							this.$api
								.setAlarmRule({
									id: this.dataSelected.id,
									ruleName: values.ruleName,
									thresholdValue: values.thresholdValue,
									alarmType: values.alarmType,
									alarmGrade: values.alarmGrade,
									pushRuleId: values.pushRuleId
								})
								.then((res) => {
									if (res.code === 200) {
										this.editVisible = false
										this.queryAlarmRuleDetail()
									}
								})
								.catch((err) => {
									console.log(err)
								})
						} else {
							this.$api
								.copyAlarmRule({
									ruleId: this.dataSelected.ruleId,
									ruleName: values.ruleName,
									thresholdValue: values.thresholdValue,
									alarmType: values.alarmType,
									alarmGrade: values.alarmGrade,
									pushRuleId: values.pushRuleId
								})
								.then((res) => {
									if (res.code === 200) {
										this.editVisible = false
										this.queryAlarmRuleDetail()
									}
								})
								.catch((err) => {
									console.log(err)
								})
						}
					}
				})
			},
			btnCancel() {
				this.editVisible = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.search-input {
		width: 120px;
	}
</style>
